import React from 'react';

import cx from 'classnames';

import { Text } from '../Text';

import css from './GameCard.module.css';

interface GameCharacteristicProps {
  label: string;
  style?: React.CSSProperties;
  className?: string;
  children?: JSX.Element | null | string | number;
}

export const GameCharacteristic: React.FC<GameCharacteristicProps> = ({
  label,
  style,
  children,
  className,
}) => {
  if (typeof children === 'number' && children > 99999) {
    return null;
  }

  return (
    <div className={cx(css.characteristic, className)} style={style}>
      <Text.Regular className={css.characteristicContent} size={4} style={style}>
        {children}
      </Text.Regular>
      <Text.Regular className={css.characteristicLabel} size={4} style={style}>
        {label}
      </Text.Regular>
    </div>
  );
};
