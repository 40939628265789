import React, { memo } from 'react';

import cx from 'classnames';

import { api } from 'api';

import { Avatar } from '../../Avatar';
import { CardParam } from '../../CardParams';
import { Icon, IconProps } from '../../Icons';
import {
  AllianceLogoIcon,
  HordeLogoIcon,
  WowCovenantIcon,
} from '../../Icons__deprecated';
import { useTranslations } from '../../Language';

import { CardFieldsProps } from '../GameCard';
import css from '../GameCard.module.css';
import { GameCharacteristic } from '../GameCharacteristic';
import { mapRioScoreToColor, rioClassColors } from '../wow';

type CardFieldsWowProps = CardFieldsProps<api.Wow>;
type CardFieldsWowBurningCrusadeProps = CardFieldsProps<api.WowBurningCrusade>;

export const CardFieldsWow = React.memo<CardFieldsWowProps>(({ game }) => {
  // const text = useText(state => state.gameData.wow);

  return (
    <div className={css.details}>
      <article className={css.detailsCharacter}>
        <GameCharacteristic
          label="Mythic+"
          style={{
            color: mapRioScoreToColor(game.rioScore || 0),
            borderColor: mapRioScoreToColor(game.rioScore || 0),
          }}
        >
          {Math.ceil(game.rioScore || 0)}
        </GameCharacteristic>

        <GameCharacteristic className={css.fadedCharacteristic} label="ilvl">
          {game.ilvl}
        </GameCharacteristic>
      </article>
    </div>
  );
});

export const CardFieldsWowBurningCrusade =
  React.memo<CardFieldsWowBurningCrusadeProps>(({ game }) => {
    return (
      <div className={css.details}>
        <article className={css.detailsCharacter}>
          <WowIlvlParam value={game.ilvl} />
          <WowArenaScoreParam value={game.arenaScore} />
        </article>
      </div>
    );
  });

export const WowMythicPlusParam = React.memo<{
  game: api.Maybe<api.Wow>;
  disabledLink?: boolean;
  className?: string;
  isPreview?: boolean;
}>(({ game, disabledLink = false, className, isPreview = false }) => {
  if (!game) {
    return null;
  }

  return (
    <CardParam
      className={className}
      externalLink={
        !disabledLink && `https://raider.io/characters/eu/${game.realm}/${game.alias}`
      }
      isPreview={isPreview}
    >
      Mythic+: {Math.ceil(game.rioScore || 0)}
    </CardParam>
  );
});

export const WowArenaScoreParam = React.memo<{
  value: api.Maybe<number>;
}>(({ value }) => {
  if (!value) {
    return null;
  }

  return <GameCharacteristic label="Arena score">{value}</GameCharacteristic>;
});

export const WowIlvlParam = React.memo<{
  value: api.Maybe<number>;
}>(({ value }) => {
  if (!value) {
    return null;
  }

  return <GameCharacteristic label="Item level">{value}</GameCharacteristic>;
});

export const WowClass: React.FC<GameInfoProps> = ({ game }) => {
  const text = useTranslations();

  if (game.__typename !== 'Wow' || !game.thumbnailUrl) {
    return null;
  }

  if (!game.wowClass) {
    return null;
  }

  const getFactionIcon = () => {
    switch (game.wowFaction) {
      case api.WowFaction.Alliance:
        return AllianceLogoIcon;

      case api.WowFaction.Horde:
        return HordeLogoIcon;
      case undefined:
        return AllianceLogoIcon;
    }
  };

  const FactionIcon = getFactionIcon();

  if (game.__typename === 'Wow' && game.wowSpecialization) {
    return (
      <div className={css.faction}>
        {game.wowFaction ? <FactionIcon className={css.factionIcon} /> : null}
        <span className={css.class}>
          {text.wow.specialization(game.wowSpecialization)}
        </span>
      </div>
    );
  }

  return (
    <div className={css.faction}>
      {game.wowFaction ? <FactionIcon className={css.factionIcon} /> : null}
      <span className={css.class}>{text.wow.class(game.wowClass)}</span>
    </div>
  );
};

export const WowFullAvatar: React.FC<GameInfoProps> = ({ game }) => {
  if (game.__typename !== 'Wow' || !game.thumbnailUrl) {
    return null;
  }

  return (
    <div
      className={css.avatarContainer}
      style={{
        borderColor: rioClassColors[game.wowClass || api.WowClass.Warrior],
      }}
    >
      <WowAvatar className={css.avatar} game={game} size={128} />

      {game.covenant ? (
        <WowCovenantIcon
          className={css.covenantIcon}
          size={40}
          wowCovenant={(game as api.Wow).covenant}
        />
      ) : null}
    </div>
  );
};

interface WowRoleSvgIconProps extends IconProps {
  role: api.Maybe<api.WowRole>;
}
export const WowRoleSvgIcon = memo<WowRoleSvgIconProps>(({ role, ...props }) => {
  if (!role) {
    return null;
  }
  switch (role) {
    case api.WowRole.Damage:
      return <Icon.Damage {...props} />;
    case api.WowRole.Healer:
      return <Icon.Healer {...props} />;
    case api.WowRole.Tank:
      return <Icon.Tank {...props} />;
  }
});

export const WowRole: React.FC<GameInfoProps> = ({ game }) => {
  if (game.__typename !== 'Wow' || !game.wowRole) {
    return null;
  }

  return <WowRoleSvgIcon className={css.wowRoleIcon} role={game.wowRole} />;
};

interface WowAvatarProps {
  game: api.Game;
  size?: number;
  className?: string;
}
export const WowAvatar = React.memo<WowAvatarProps>(
  ({ game, size = 32, className }) => {
    if (game.__typename !== 'Wow' || !game.thumbnailUrl) {
      return null;
    }

    return (
      <Avatar
        className={cx(css.wowAvatar, className)}
        size={size}
        url={game.thumbnailUrl}
      />
    );
  },
);

export interface GameInfoProps {
  game: api.Game;
}
