import React from 'react';

import { api } from '../../../api';

import { CardFieldsProps } from '../GameCard';
import css from '../GameCard.module.css';
import { GameCharacteristic } from '../GameCharacteristic';

type CardFieldsWowProps = CardFieldsProps<api.Hearthstone>;

export const CardFieldsHearthstone = React.memo<CardFieldsWowProps>(({ game }) => {
  return (
    <div className={css.details}>
      <article className={css.detailsCharacter}>
        <HsRatingParam game={game} />
        <HsRegionParam game={game} />
      </article>
    </div>
  );
});

export const HsRatingParam = React.memo<{
  game: api.Hearthstone;
}>(({ game }) => {
  if (!game.rating) {
    return null;
  }

  return (
    <GameCharacteristic label="Rating">
      {Math.ceil(game.rating || 0)}
    </GameCharacteristic>
  );
});

export const HsRegionParam = React.memo<{
  game: api.Hearthstone;
}>(({ game }) => {
  if (!game.hsRegion) {
    return null;
  }

  return <GameCharacteristic label="Region">{game.hsRegion}</GameCharacteristic>;
});
