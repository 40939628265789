import React, { useMemo, useCallback } from 'react';

import cx from 'classnames';

import { GameCardCategory } from 'analytics/playerFeed';
import { api } from 'api';

import { Button } from '../Button';
import { Error } from '../Error';

import { GameCard2 } from '../GameCard2';
import { Layout } from '../Layout';
import { Text } from '../Text';

import { GameAction } from './GameCard';

import css from './GameCard.module.css';

interface GameCardListProps {
  // HashMap if groups are to be separated
  games: api.Maybe<api.Game[]> | { [label: string]: api.Game[] };
  gameCardCategory: GameCardCategory;
  formatLabel?: (key: GameTitle) => string;

  sort?: (games: api.Game[]) => api.Game[];

  isLoading?: boolean;
  hasMore?: boolean;

  onRequestMore?: () => Promise<void> | void;

  onRequestAddNewGame?: () => Promise<void> | void;
  canAddNew?: boolean;

  filterGame?: api.Game['__typename'];
  user: api.User;
  isMe: boolean;
  withLinks?: boolean;
  withCursorPointer?: boolean;
  canEdit?: boolean;
  onClick?: (game: api.Game) => void;
  onRequestAction?: (game: api.Game, action: GameAction) => void;
  className?: string;
}

type GameTitle = NonNullable<api.Game['__typename']>;

export const GameCardList = React.memo<GameCardListProps>(
  ({
    games,
    isLoading = true,
    hasMore = false,
    sort = games => games,
    onRequestMore = () => undefined,
    formatLabel = label => label,

    filterGame,
    onClick = () => undefined,
    onRequestAction = () => undefined,
    className,
    user,
    ...props
  }) => {
    const renderList = useCallback(
      (games: api.Game[]): React.ReactNode[] => {
        return sort(games).map(game => (
          <GameCard2
            key={game.id}
            game={game}
            user={user}
            onClick={() => onClick(game)}
            onRequestAction={action => onRequestAction(game, action)}
            {...props}
            canEdit={Boolean(props.canEdit && props.isMe)}
          />
        ));
      },
      [games, sort],
    );

    const renderLoadMore = (): React.ReactNode => {
      if (!hasMore) {
        return null;
      }

      return <Button onClick={onRequestMore}>Load more</Button>;
    };

    if (games) {
      if (Array.isArray(games)) {
        return (
          <section className={cx(css.cardList, className)}>
            {/* {canAddNew && <GameAddNew onClick={onRequestAddNewGame} />} */}
            {renderList(
              filterGame
                ? games.filter(game => game.__typename === filterGame)
                : games,
            )}
            {renderLoadMore()}
          </section>
        );
      }

      return (
        <section className={cx(css.cardList, className)}>
          {/* {canAddNew && <GameAddNew onClick={onRequestAddNewGame} />} */}
          {Object.keys(games).map(key => (
            <React.Fragment key={key}>
              <Text.Subtitle className={css.cardListSeparator} id={key}>
                {formatLabel(key as GameTitle)}
              </Text.Subtitle>
              <div className={css.gameList}>{renderList(games[key])}</div>
            </React.Fragment>
          ))}
          {renderLoadMore()}
        </section>
      );
    }

    if (isLoading) {
      return <Layout.Loading />;
    }

    return <Error>No games added</Error>;
  },
);

interface ListGroupedByProps {
  games: api.Maybe<api.Game[]>;
  sort?: (games: api.Game[]) => api.Game[];
  gameCardCategory: GameCardCategory;

  isLoading?: boolean;
  hasMore?: boolean;
  canAddNew?: boolean;
  onRequestAddNewGame?: () => Promise<void> | void;
  onRequestMore?: () => Promise<void> | void;

  filterGame?: api.Game['__typename'];
  user: api.User;
  isMe: boolean;
  withLinks?: boolean;
  withCursorPointer?: boolean;
  canEdit?: boolean;
  onClick?: (game: api.Game) => void;
  onRequestAction?: (game: api.Game, action: GameAction) => void;
}

export const GameCardListGroupedByTitle = React.memo<ListGroupedByProps>(
  ({ games, ...props }) => {
    const groupedGames: api.Maybe<Record<GameTitle, api.Game[]>> = useMemo(() => {
      return games?.reduce((acc, game) => {
        const label = game.__typename;

        if (!label) {
          return acc;
        }

        if (acc[label]) {
          return { ...acc, [label]: [...acc[label], game] };
        }

        return { ...acc, [label]: [game] };
      }, {} as Record<GameTitle, api.Game[]>);
    }, [games]);

    return (
      <GameCardList formatLabel={formatGameTitle} games={groupedGames} {...props} />
    );
  },
);

export function formatGameTitle(title: GameTitle): string {
  switch (title) {
    case 'Hearthstone':
      return 'Hearthstone';
    case 'Wow':
      return 'World of Warcraft: Shadowlands';
    case 'WowBurningCrusade':
      return 'World of Warcraft: Burning Crusade';
    case 'CSGO':
      return 'CS:GO';
    case 'Warzone':
      return 'Warzone';
    case 'LostArk':
      return 'Lost Ark';
    case 'GameLol':
      return 'League of Legends';
    case 'GameAny':
      return 'Other';
    case 'GameAny':
      return 'Other';
    default:
      return 'Other';
  }
}
