import React from 'react';

import { api } from 'api';
import { utils } from 'utils';

import { WowAvatar } from '../GameCard/CardFields/CardFieldsWow';
import { mapRioScoreToColor, rioClassColors } from '../GameCard/wow';
import {
  AllianceLogoIcon,
  BattleNetIcon,
  HordeLogoIcon,
  NewLogoIcon,
  WowCovenantIcon,
} from '../Icons__deprecated';
import { DynamicTranslations, useTranslations } from '../Language';

import { MetaData } from './GamePreview';

import css from './WowPreview.module.css';

interface WowPreviewProps {
  game: api.Wow | api.WowBurningCrusade;
  user: api.User;
}

interface GameCharacteristicProps {
  label: string;
  style?: React.CSSProperties;
  children?: JSX.Element | JSX.Element[] | null | string | number;
}

const GameCharacteristic: React.FC<GameCharacteristicProps> = ({
  label,
  style,
  children,
}) => {
  return (
    <div className={css.characteristic} style={style}>
      <div className={css.characteristicContent} style={style}>
        {children}
      </div>
      <span className={css.characteristicLabel}>{label}</span>
    </div>
  );
};

export const WowPreview: React.FC<WowPreviewProps> = ({ game, user }) => {
  const text = useTranslations();

  const WowClass = () => {
    if (!game.wowClass) {
      return null;
    }

    const getFactionIcon = () => {
      switch (game.wowFaction) {
        case api.WowFaction.Alliance:
          return AllianceLogoIcon;

        case api.WowFaction.Horde:
          return HordeLogoIcon;
        case undefined:
          return AllianceLogoIcon;
      }
    };

    const FactionIcon = getFactionIcon();

    if (game.__typename === 'Wow' && game.wowSpecialization) {
      return (
        <div className={css.faction}>
          {game.wowFaction ? <FactionIcon className={css.factionIcon} /> : null}
          <span className={css.class}>
            {text.wow.specialization(game.wowSpecialization)}
          </span>
        </div>
      );
    }

    return (
      <div className={css.faction}>
        {game.wowFaction ? <FactionIcon className={css.factionIcon} /> : null}
        <span className={css.class}>{text.wow.class(game.wowClass)}</span>
      </div>
    );
  };

  return (
    <div className={css.wowGameContainer}>
      <div
        className={css.mainGameInfo}
        style={{
          backgroundColor: rioClassColors[game.wowClass || api.WowClass.Warrior],
        }}
      >
        <div className={css.avatarContainer}>
          <WowAvatar className={css.avatar} game={game} size={1024} />
          {(game as api.Wow).covenant ? (
            <WowCovenantIcon
              className={css.covenantIcon}
              size={40}
              wowCovenant={(game as api.Wow).covenant}
            />
          ) : null}
        </div>

        <div className={css.gameInfo}>
          <span className={css.login}>{game.alias}</span>
          <WowClass />
        </div>
        <NewLogoIcon className={css.logoIcon} />
      </div>
      <div className={css.additionalGameInfo}>
        {game.__typename === 'Wow' && (
          <GameCharacteristic
            label="Mythic+"
            style={{
              color: mapRioScoreToColor(game.rioScore || 0),
              borderColor: mapRioScoreToColor(game.rioScore || 0),
            }}
          >
            {Math.ceil(game.rioScore || 0)}
          </GameCharacteristic>
        )}
        <GameCharacteristic label="ilvl">
          {Math.ceil(game.ilvl || 0)}
        </GameCharacteristic>
        {user.battlenet ? (
          <GameCharacteristic label={user.battlenet?.battletag}>
            <BattleNetIcon className={css.battlenetIcon} />
            <span className={css.region}>
              {text.wow.region(user.battlenet.region)}
            </span>
          </GameCharacteristic>
        ) : null}
      </div>
      <div className={css.blackLine}></div>
    </div>
  );
};

export function getMetaWow(
  text: DynamicTranslations,
  game: api.Wow,
  user: api.User,
): MetaData {
  /* login <guild> @ Server (EU) */
  const guild = game.guild ? ` <${game.guild}>` : '';
  const realm = ` @ ${utils.capitalize(game.realm || '')}`;
  const region = user?.battlenet?.region
    ? ` (${text.wow.region(user.battlenet.region)})`
    : '';

  const title = game.alias + guild + realm + region;

  /*
   * Fraction Spec/class, ilvl ${ilvl}, Mythic+ Score ${score}
   * [\n Comment: ${desc}]
   */
  const faction = text.wow.faction(game.wowFaction || api.WowFaction.Horde);
  const spec = game.wowSpecialization
    ? text.wow.specialization(game.wowSpecialization)
    : text.wow.class(game.wowClass);
  const ilvl = `ilvl ${game.ilvl || 0}`;
  const rating = `Mythic+ Score ${game.rioScore || 0}`;
  const comment = game.description ? `\nComment: ${game.description}` : '';

  const description = `${faction} ${spec}, ${ilvl}, ${rating}` + comment;

  const updatedAt = game.updatedAt ? new Date(game.updatedAt).getTime() : 1;

  const image = `https://preview.lf.group/game/${game.id}`;

  return { title, description, image, updatedAt };
}
