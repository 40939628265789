import React from 'react';

import { api } from 'api';

import { AvatarUser } from '../Avatar';
import { useTranslations, useText, DynamicTranslations } from '../Language';
import { LolChampionsAvatar } from '../ProfileCardList/ProfileInfoGames/ProfileInfoLol';
import { Text } from '../Text';

import { MetaData } from './GamePreview';

import css from './LolPreview.module.css';

interface LolPreviewProps {
  game: api.GameLol;
  user: api.User;
}

const UserInfo: React.FC<LolPreviewProps> = ({ user, game }) => {
  const previewBackground = game.champions && game.champions[0].image;

  return (
    <div
      className={css.userInfoWrapper}
      style={{ backgroundImage: `url("${previewBackground}")` }}
    >
      <article className={css.userInfoContainer}>
        <div className={css.avatarContainer}>
          <AvatarUser className={css.avatar} user={user} />
        </div>
        <div className={css.userInfo}>
          <Text.H1 className={css.gameName}>{user.riot?.gameName}</Text.H1>
          {user.riot?.tagName ? (
            <Text.Regular semibold className={css.tagName}>
              #{user.riot?.tagName}
            </Text.Regular>
          ) : null}
        </div>
      </article>
    </div>
  );
};

const Param: React.FC<{
  children?: JSX.Element | null | string | number | string[];
  title: string;
}> = ({ children, title }) => (
  <div style={{ display: 'flex', flexDirection: 'column', gap: 6 }}>
    <Text.Regular className={css.stata} style={{ opacity: 0.48 }}>
      {title}
    </Text.Regular>
    <Text.Regular semibold className={css.stata}>
      {children}
    </Text.Regular>
  </div>
);

const LolStats: React.FC<Omit<LolPreviewProps, 'user'>> = ({ game }) => {
  const translation = useTranslations();
  const text = useText(state => state.gameData.lol);

  const wins = game.soloWinCount || game.normalWinCount || 0;
  const loses = game.loseCount || game.normalLoseCount || 0;
  const games = wins + loses || 1;

  return (
    <>
      <div className={css.statsContainer}>
        <LolChampionsAvatar
          champions={game.champions}
          role={game.preferredLine}
          size={100}
        />

        <Param title={text.rank.solo.full}>
          {translation.wildRift.rank(game.soloRank)}
        </Param>

        <Param title={text.rank.flex.full}>
          {translation.wildRift.rank(game.flexRank)}
        </Param>
      </div>

      <div className={css.statsContainer}>
        {game.champions ? (
          <Param title={text.kda}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {game.champions.map((champ, index) => (
                <React.Fragment key={index}>
                  {index !== 0 && <div className={css.dot} />}
                  <Text.Regular semibold className={css.stata}>
                    {champ.KDA?.toFixed(1)}
                  </Text.Regular>
                </React.Fragment>
              ))}
            </div>
          </Param>
        ) : null}

        <Param title={text.winRate.title.full}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Text.Regular className={css.stata} style={{ color: '#2578f5' }}>
              {text.winRate.wins.full(wins)}
            </Text.Regular>
            <div className={css.dot} />
            <Text.Regular className={css.stata} style={{ color: '#c5306e' }}>
              {text.winRate.loses.full(loses)}
            </Text.Regular>
            <div className={css.dot} />
            <Text.Regular className={css.stata}>
              {Math.round((100 * wins) / games)}%
            </Text.Regular>
          </div>
        </Param>
      </div>
    </>
  );
};

export const LolPreview: React.FC<LolPreviewProps> = ({ game, user }) => {
  return (
    <div className={css.previewContainer}>
      <div className={css.logo}>
        <picture>
          <img
            alt="logo"
            className={css.logoImage}
            src="https://storage.googleapis.com/lfgcdn/assets/logo-2@2x.png"
          />
        </picture>
      </div>
      <UserInfo game={game} user={user} />

      <LolStats game={game} />
    </div>
  );
};

export function getMetaLol(
  text: DynamicTranslations,
  game: api.GameLol,
  user: api.User,
): MetaData {
  // const region = user?.riot?.region
  //   ? ` (${text.wildRift.region(user.riot.region)})`
  //   : '';
  const userName = user.riot?.gameName + '#' + user.riot?.tagName;

  // text.lol.region()
  const title = userName + '';

  const soloQ = text.wildRift.rank(game.soloRank);
  const flexQ = text.wildRift.rank(game.flexRank);

  const wins = game.winCount || 0;
  const loses = game.loseCount || 0;
  const games = wins + loses;
  const winRate = Math.round((wins * 100) / (games || 1));

  const description = `soloQ: ${soloQ}, flexQ: ${flexQ}. Wins: ${wins} / Loses: ${loses} (${winRate}%)`;

  // const updatedAt = game.updatedAt ? new Date(game.updatedAt).getTime() : 1;

  const image = `https://preview.lf.group/game/${game.id}`;

  return { title, description, image };
}
