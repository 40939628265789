import React from 'react';

import { api } from 'api';

import { AvatarUser } from '../Avatar';
import { LostArkClassIcon } from '../Icons__deprecated';
import { DynamicTranslations, useTranslations } from '../Language';
import { Text } from '../Text';

import { MetaData } from './GamePreview';

import css from './LostArkPreview.module.css';

interface LostArkPreviewProps {
  game: api.LostArk;
  user: api.User;
}

interface LostArkPreviewParamProps {
  children: string;
  value?: string | number;
  checkValue?: boolean;
}

const LostArkPreviewParam: React.FC<LostArkPreviewParamProps> = ({
  children,
  value,
  checkValue = false,
}) => {
  if (!value && checkValue) {
    return null;
  }

  if (!children) {
    return null;
  }

  return <Text.H1 className={css.previewParam}>{children}</Text.H1>;
};

const LostArkUserInfo: React.FC<Omit<LostArkPreviewProps, 'game'>> = ({ user }) => {
  const lostArkLfgs = user?.lfg?.filter(lfg => lfg?.gameId === api.GameId.LostArkEn);
  const lostArkLfg = lostArkLfgs ? lostArkLfgs[0] : undefined;
  const region = lostArkLfg?.lostArk?.region ? lostArkLfg?.lostArk?.region[0] : '';
  const server = lostArkLfg?.lostArk?.server ? lostArkLfg?.lostArk?.server[0] : '';

  return (
    <div className={css.userInfoWrapper}>
      <div className={css.avatarWrapper}>
        <AvatarUser className={css.avatar} user={user} />
      </div>
      <div className={css.userInfo}>
        <Text.H1 className={css.username}>{user.username}</Text.H1>
        <div className={css.params}>
          <LostArkPreviewParam checkValue value={lostArkLfg?.lostArk?.legacyLvl}>
            {`Roster lvl ${lostArkLfg?.lostArk?.legacyLvl}`}
          </LostArkPreviewParam>
          <LostArkPreviewParam>{region}</LostArkPreviewParam>
          <LostArkPreviewParam>{server}</LostArkPreviewParam>
        </div>
      </div>
    </div>
  );
};

const LostArkGameInfo: React.FC<LostArkPreviewProps> = ({ user }) => {
  const translation = useTranslations();

  const lostArkLfgs = user?.lfg?.filter(lfg => lfg?.gameId === api.GameId.LostArkEn);
  const lostArkLfg = lostArkLfgs ? lostArkLfgs[0] : undefined;

  if (!lostArkLfg) {
    return null;
  }

  const games = lostArkLfg.games as api.LostArk[];
  const renderSecondaryInfo = (game: api.LostArk) => {
    if (game.gearScore && game.class) {
      return `${game.gearScore}, ${translation.lostArk.class(game.class)}`;
    }
    if (game.class) {
      return `${translation.lostArk.class(game.class)}`;
    }

    return game.gearScore;
  };

  return (
    <div className={css.gameInfoWrapper}>
      {games?.map((game, key) => (
        <div key={key} className={css.lostArkGame}>
          {game.class ? (
            <LostArkClassIcon
              className={css.lostArkClass}
              lostArkClass={game.class}
              size={128}
            />
          ) : null}
          <div className={css.lostArkGameInfo}>
            <Text.Regular className={css.lostArkGameMainInfo}>
              {game.alias}
            </Text.Regular>
            <Text.Regular className={css.lostArkGameSecondaryInfo}>
              {renderSecondaryInfo(game)}
            </Text.Regular>
          </div>
        </div>
      ))}
    </div>
  );
};

export const LostArkPreview: React.FC<LostArkPreviewProps> = ({ game, user }) => {
  return (
    <div className={css.previewContainer}>
      <div className={css.logo}>
        <picture>
          <img
            alt="logo"
            className={css.logoImage}
            src="https://storage.googleapis.com/lfgcdn/assets/logo-2@2x.png"
          />
        </picture>
      </div>
      <LostArkUserInfo user={user} />
      <LostArkGameInfo game={game} user={user} />
    </div>
  );
};

export function getMetaLostArk(
  translation: DynamicTranslations,
  game: api.LostArk,
  user: api.User,
): MetaData {
  // site nickname (Region, Server)
  // Roster XX. Comment

  const lfgs = user?.lfg?.filter(lfg => lfg?.gameId === api.GameId.LostArkEn);
  const lfg = lfgs ? lfgs[0] : undefined;

  const getTitleInfo = () => {
    const region =
      lfg?.lostArk?.region && (lfg.lostArk.region[0] as api.LostArkRegion);
    const server = lfg?.lostArk?.server;

    if (server && region) {
      return `(${translation.lostArk.region(region)}, ${server})`;
    }

    if (region) {
      return `(${translation.lostArk.region(region)})`;
    }

    if (server) {
      return `(${server})`;
    }

    return '';
  };

  const title = `${user.username} ${getTitleInfo()}`;

  const legacy = lfg?.lostArk?.legacyLvl
    ? `Roster lvl ${lfg?.lostArk?.legacyLvl}. `
    : '';
  const buildsInfo = ' Character builds are listed on the site.';

  const description = legacy + lfg?.description + buildsInfo;

  const image = `https://preview.lf.group/game/${game.id}`;

  return { title, description, image };
}
