import React from 'react';

import cx from 'classnames';

import { api } from 'api';

import { parseGameTypenameToId } from 'utils/main';

import { GameIcon, WowAllianceIcon, WowHordeIcon } from '../Icons__deprecated';

import css from './GameCard.module.css';

interface GameCardIconProps {
  gameType: api.Game['__typename'];
  faction?: api.Maybe<api.WowFaction>;
  width?: number;
  height?: number;
  className?: string;
  forceMimeType?: 'png' | 'webp';
}

export const GameCardIcon = React.memo<GameCardIconProps>(
  ({ gameType, faction, className, forceMimeType, width, height }) => {
    switch (gameType) {
      case 'Wow':
        if (faction === api.WowFaction.Alliance) {
          return (
            <WowAllianceIcon
              className={cx(css.bgIcon, className)}
              forceMimeType={forceMimeType}
              height={height}
              size={128}
              width={width}
            />
          );
        }
        if (faction === api.WowFaction.Horde) {
          return (
            <WowHordeIcon
              className={cx(css.bgIcon, className)}
              forceMimeType={forceMimeType}
              height={height}
              size={128}
              width={width}
            />
          );
        }

        // TODO: @reginPekin Create Universal component for GameIcon by GameId. Reuse wherever possible
        return (
          <GameIcon
            className={cx(css.bgIcon, className)}
            forceMimeType={forceMimeType}
            gameId={api.GameId.WorldOfWarcraft}
            height={height}
            size={128}
            width={width}
          />
        );

      case 'WowBurningCrusade':
        if (faction === api.WowFaction.Alliance) {
          return (
            <WowAllianceIcon
              className={cx(css.bgIcon, className)}
              forceMimeType={forceMimeType}
              height={height}
              size={128}
              width={width}
            />
          );
        }
        if (faction === api.WowFaction.Horde) {
          return (
            <WowHordeIcon
              className={cx(css.bgIcon, className)}
              forceMimeType={forceMimeType}
              height={height}
              size={128}
              width={width}
            />
          );
        }

        return (
          <GameIcon
            className={cx(css.bgIcon, className)}
            forceMimeType={forceMimeType}
            gameId={api.GameId.WorldOfWarcraftBurningCrusade}
            height={height}
            size={128}
            width={width}
          />
        );
      case 'Hearthstone':
        return (
          <GameIcon
            className={cx(css.bgIcon, className)}
            forceMimeType={forceMimeType}
            gameId={api.GameId.Hearthstone}
            height={height}
            size={128}
            width={width}
          />
        );
      case 'LostArk':
        return (
          <GameIcon
            className={cx(css.bgIcon, className)}
            forceMimeType={forceMimeType}
            gameId={api.GameId.LostArk}
            height={height}
            size={128}
            width={width}
          />
        );
      case 'CSGO':
        return (
          <GameIcon
            className={cx(css.bgIcon, className)}
            forceMimeType={forceMimeType}
            gameId={api.GameId.Csgo}
            height={height}
            size={128}
            width={width}
          />
        );
      case 'GameAny':
        return (
          <GameIcon
            className={cx(css.bgIcon, className)}
            forceMimeType={forceMimeType}
            gameId={api.GameId.Custom}
            height={height}
            size={128}
            width={width}
          />
        );
      case 'Warzone':
        return (
          <GameIcon
            className={cx(css.bgIcon, className)}
            forceMimeType={forceMimeType}
            gameId={api.GameId.Warzone}
            height={height}
            size={128}
            width={width}
          />
        );
      case 'GameLol':
        return (
          <GameIcon
            className={cx(css.bgIcon, className)}
            forceMimeType={forceMimeType}
            gameId={api.GameId.LeagueOfLegends}
            height={height}
            size={128}
            width={width}
          />
        );
      case undefined:
        return null;
      default:
        // TODO: @reginPekin Create Universal component for GameIcon by GameId. Reuse wherever possible
        return (
          <GameIcon
            className={cx(css.bgIcon, className)}
            forceMimeType={forceMimeType}
            gameId={parseGameTypenameToId(gameType)}
            height={height}
            size={128}
            width={width}
          />
        );
    }
  },
);
