import React, { useMemo } from 'react';

import cx from 'classnames';

import { api } from 'api';
import { utils } from 'utils';

import { Meta } from 'utils/meta';

import { CardParam } from '../CardParams';
import * as CardFields from '../GameCard/CardFields';
import { WowAvatar } from '../GameCard/CardFields/CardFieldsWow';
import { CardFieldsProps } from '../GameCard/GameCard';
import { GameCardIcon } from '../GameCard/GameCardIcon';
import { formatGameTitle } from '../GameCard/GameCardList';
import { mapClassToStyle, mapFactionToStyle } from '../GameCard/wow';
import { GameIcon, WowClassIcon, WowRoleIcon } from '../Icons__deprecated';
import { useText, useTranslations } from '../Language';
import { Text } from '../Text';

import css from './GamePreview.module.css';

import { LolPreview, getMetaLol } from './LolPreview';
import { LostArkPreview, getMetaLostArk } from './LostArkPreview';
import { getMetaWow, WowPreview } from './WowPreview';

interface GamePreviewProps {
  game: api.Game;
  user: api.User;
}

export interface MetaData {
  title?: string;
  description?: string;
  image?: string;
  updatedAt?: number;
}

export const GamePreview: React.FC<GamePreviewProps> = ({ game, user }) => {
  const gameId = useMemo(() => utils.parseGameTypenameToId(game.__typename), [game]);

  const renderDetails = () => {
    if (!game.__typename) {
      return null;
    }

    switch (game.__typename) {
      case 'Wow':
        return (
          <CardFields.Wow
            isPreview
            className={css.cardFields}
            game={game}
            user={user}
          />
        );
      case 'Hearthstone':
        return (
          <CardFields.Hearthstone
            isPreview
            className={css.cardFields}
            game={game}
            user={user}
          />
        );
    }
  };

  const getFaction = () => {
    if (game.__typename === 'Wow' || game.__typename === 'WowBurningCrusade') {
      return game.wowFaction;
    }
  };

  const isBattlenetGame =
    game.__typename === 'Wow' ||
    game.__typename === 'WowBurningCrusade' ||
    game.__typename === 'Hearthstone' ||
    game.__typename === 'Warzone';

  if (game.__typename === 'Wow' || game.__typename === 'WowBurningCrusade') {
    return <WowPreview game={game} user={user} />;
  }

  if (game.__typename === 'GameLol') {
    return <LolPreview game={game} user={user} />;
  }
  if (game.__typename === 'LostArk') {
    return <LostArkPreview game={game} user={user} />;
  }

  return (
    <div className={css.gameContainer}>
      <section className={css.cardInfo}>
        <div className={css.infoIcons}>
          <GameIcon
            className={css.infoIcon}
            forceMimeType="png"
            gameId={gameId}
            size={128}
          />
          <WowAvatar className={css.infoIcon} game={game} size={104} />
          <WowRoleIcon
            className={css.infoIcon}
            forceMimeType="png"
            role={(game as api.Wow).wowRole}
            size={40}
          />
          <GameLogin className={css.login} game={game} user={user} />
          {user.battlenet && isBattlenetGame ? (
            <Text.Regular className={css.battletag}>
              {user.battlenet.battletag}
            </Text.Regular>
          ) : null}
        </div>

        <GameCardThumbnail game={game} user={user} />
      </section>

      {renderDetails()}

      <GameCardIcon
        className={css.gameIcon}
        faction={getFaction()}
        forceMimeType="png"
        gameType={game.__typename}
        height={810}
        width={810}
      />
    </div>
  );
};

const GameLogin = React.memo<CardFieldsProps>(({ game, user, className }) => {
  switch (game.__typename) {
    case 'Wow':
    case 'WowBurningCrusade':
    case 'Hearthstone':
    case 'CSGO':
      return (
        <span className={cx(css.login, className)}>
          {user.steam?.username ? (
            <Text.Regular>{user.steam?.username}</Text.Regular>
          ) : null}
        </span>
      );
    case 'GameLol':
      return (
        <span className={cx(css.login, className)}>
          {user.riot?.gameName ? (
            <Text.Regular>{user.riot.gameName}</Text.Regular>
          ) : null}
        </span>
      );
    case 'LostArk':
      return (
        <span className={cx(css.login, className)}>
          {game.alias ? <Text.Regular>{game.alias}</Text.Regular> : null}
        </span>
      );
    case 'Warzone':
    case 'GameAny':
    case undefined:
    default:
      return null;
  }
});

const renderRealm = (
  realm: string | undefined,
  region: api.WowRegion | undefined,
) => {
  if (!realm) {
    return region;
  }

  return region ? `(${region}) ${realm}` : realm;
};

const GameCardThumbnail = React.memo<CardFieldsProps>(({ game, user }) => {
  const text = useText(state => state.gameData.wow);
  const translations = useTranslations();

  switch (game.__typename) {
    case 'Wow':
      return (
        <div className={css.thumbnailWrapper}>
          <div className={css.rowInfo}>
            {game.realm || game.wowRegion ? (
              <CardParam className={css.cardFields}>
                {renderRealm(game.realm, game.wowRegion)}
              </CardParam>
            ) : null}
            {game.guild ? (
              <Text.Regular>
                {text.fields.guild}: {game.guild}
              </Text.Regular>
            ) : null}
          </div>
          <div className={css.rowInfo}>
            <WowClassIcon
              className={css.classIcon}
              forceMimeType="png"
              size={40}
              wowClass={(game as api.Wow).wowClass}
            />
            {game.wowSpecialization ? (
              <CardParam className={css.cardFields}>
                {game.wowSpecialization}
              </CardParam>
            ) : null}
            {game.wowRace ? (
              <Text.Regular style={mapFactionToStyle(game.wowFaction)}>
                {translations.wow.race(game.wowRace)}
              </Text.Regular>
            ) : null}
            {game.wowClass ? (
              <Text.Regular style={mapClassToStyle(game.wowClass)}>
                {translations.wow.class(game.wowClass)}
              </Text.Regular>
            ) : null}
          </div>
        </div>
      );
    case 'WowBurningCrusade':
      return (
        <div className={css.thumbnailWrapper}>
          <div className={css.rowInfo}>
            {game.realm || game.wowRegion ? (
              <CardParam className={css.cardFields}>
                {renderRealm(game.realm, game.wowRegion)}
              </CardParam>
            ) : null}
          </div>
          <div className={css.rowInfo}>
            <WowClassIcon
              className={css.classIcon}
              forceMimeType="png"
              size={40}
              wowClass={(game as api.Wow).wowClass}
            />
            {game.wowRace ? (
              <Text.Regular style={mapFactionToStyle(game.wowFaction)}>
                {translations.wow.race(game.wowRace)}
              </Text.Regular>
            ) : null}
            {game.wowClass ? (
              <Text.Regular style={mapClassToStyle(game.wowClass)}>
                {translations.wow.class(game.wowClass)}
              </Text.Regular>
            ) : null}
          </div>
        </div>
      );
    case 'Hearthstone':
      return (
        <div className={css.thumbnailWrapper}>
          {user.battlenet ? (
            <Text.Regular className={css.battletag}>
              {user.battlenet.battletag}
            </Text.Regular>
          ) : null}
        </div>
      );

    case 'CSGO':
      return (
        <div className={cx(css.thumbnailWrapper, css.rowInfo)}>
          {game.csgoRole ? (
            <Text.Regular>{translations.csgo.role(game.csgoRole)}</Text.Regular>
          ) : null}
          {game.csgoRank ? (
            <Text.Regular>{translations.csgo.rank(game.csgoRank)}</Text.Regular>
          ) : null}
        </div>
      );
    case 'Warzone':
      return (
        <div className={cx(css.thumbnailWrapper, css.rowInfo)}>
          {game.warzoneRegion ? (
            <Text.Regular>{game.warzoneRegion}</Text.Regular>
          ) : null}
        </div>
      );
    case 'LostArk':
      return (
        <div className={cx(css.thumbnailWrapper, css.rowInfo)}>
          {game.region ? <Text.Regular>{game.region}</Text.Regular> : null}
        </div>
      );
    case 'GameLol':
    case 'GameAny':
      return null;
    case undefined:
    default:
      return null;
  }
});

export function useGameMeta(
  game: api.Maybe<api.Game>,
  user: api.Maybe<api.User>,
): Meta {
  const text = useText(state => state);
  const gamesInfo = useLfgsInfo(user?.lfg);
  const translations = useTranslations();

  if (!user) {
    return {
      og: {
        title: '',
        description: '',
        image: '',
      },
    };
  }

  if (!game?.__typename) {
    const title = text.user.meta.title(user.username);

    const description = gamesInfo
      ? text.user.meta.descriptionWithGames(user.username, gamesInfo)
      : text.user.meta.description(user.username);

    const image = `https://preview.lf.group/user/${user.username}`;

    return { og: { title, description, image } };
  }

  switch (game.__typename) {
    case 'Wow':
      return getMetaWow(translations, game, user);
    case 'GameLol':
      return getMetaLol(translations, game, user);
    case 'LostArk':
      return getMetaLostArk(translations, game, user);
    default:
      const description = text.user.gameCardMeta.description(
        user.username,
        formatGameTitle(game.__typename),
      );
      const title = text.user.gameCardMeta.title(
        user.username,
        formatGameTitle(game.__typename),
      );

      const image = `https://preview.lf.group/user/${user.username}`;

      return { og: { title, description, image } };
  }
}

const useLfgsInfo = (lfg: api.Maybe<api.Lfg[]>) => {
  const translations = useTranslations();

  const getGameServer = (game: api.Lfg) => {
    const gameName = translations.gameTitle(game.gameId).short;

    switch (game.gameId) {
      case api.GameId.WorldOfWarcraft:
        return game.wow?.region ? `${gameName} ${game.wow?.region}` : gameName;
      case api.GameId.LeagueOfLegends:
        return game.lol?.region ? `${gameName} ${game.lol?.region}` : gameName;
      case api.GameId.LostArkEn:
        return game.lostArk?.region && game.lostArk?.region.length
          ? `${gameName} ${game.lostArk?.region[0]}`
          : gameName;

      default:
        return gameName;
    }
  };

  if (!lfg || !lfg.length) {
    return null;
  }

  const games = lfg.map(game => getGameServer(game));

  return games.join(', ');
};
